import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getPrivateRetrieveTaskCondition,
  getPrivateTasks,
  setPrivateClaimedTaskReward,
  setPrivateStartTask,
} from '../../../../api/tasks';
import NoTaskImg from '../../../../assets/images/task/no-task.webp';
import { Loader } from '../../../../components/Loader';
import useFetch from '../../../../hooks/useFetch';
import { BottomSheetModal } from '../../../../horizon-components/bottom-sheet-modal';
import { TTaskType } from '../../../../models/Tasks';
import { CongratulationTaskModal } from '../../../../widgets/congratulation-task-modal';
import { QuestCard } from '../../../../widgets/quest-card';
import { TaskInfoModal } from '../../../../widgets/task-info-modal';
import { useUserContext } from '../../../../providers';

export const ProfileTasksQuests = () => {
  const { t } = useTranslation(['translation']);
  const [isOpen, setIsOpen] = useState(false);
  const [checkTask, setCheckTask] = useState<TTaskType>();
  const { user, updateWallet } = useUserContext();

  const { data, isLoading, fetchData } = useFetch(getPrivateTasks);
  const {
    data: reward,
    isLoading: isRewardLoading,
    fetchData: fetchReward,
    clearData: clearReward,
  } = useFetch(setPrivateClaimedTaskReward);
  const {
    data: condition,
    isLoading: isConditionLoading,
    fetchData: fetchCondition,
    clearData: clearCondition,
  } = useFetch(getPrivateRetrieveTaskCondition);

  const handleData = useCallback(() => {
    fetchData({ execution_frequency: 'DAILY,ONE_TIME' });
  }, [fetchData]);

  useEffect(() => {
    handleData();
  }, [handleData, t]);

  const handleStart = (task: TTaskType) => {
    setPrivateStartTask(task.id)
      .then((res) => {
        if (res?.status === 'success' && task.task_progress.status === 'PENDING') {
          !isOpen && handleToggle();
          task?.id &&
            setCheckTask({
              ...task,
              task_progress: {
                ...task?.task_progress,
                status: 'RUNNING',
              },
            });
        }
      })
      .finally(() => handleData());
  };

  const handleCondition = (id: number) => {
    fetchCondition(id).then((res) => res?.id && !isOpen && handleToggle());
  };

  const handleReward = (task: TTaskType) => {
    fetchReward(task.id)
      .then((res) => {
        if (res?.status === 'success') {
          !isOpen && handleToggle();
          task?.id &&
            setCheckTask({
              ...task,
              task_progress: {
                ...task?.task_progress,
                status: 'REWARD_RECEIVED',
              },
            });
          user?.guid && updateWallet();
        }
      })
      .finally(handleData);
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
    isOpen && clearReward();
    isOpen && clearCondition();
  };

  const endedTasks = useMemo(
    () =>
      data?.results?.filter(
        (item) => item.task_progress.status === 'REWARD_RECEIVED'
      ),
    [data]
  );

  const activeTasks = useMemo(
    () =>
      data?.results?.filter(
        (item) => item.task_progress.status !== 'REWARD_RECEIVED'
      ),
    [data]
  );

  return (
    <div
      className={
        'inline-flex w-full flex-col items-start justify-start gap-4 rounded-[30px] bg-white p-0 lg:p-6 lg:shadow-md lg:shadow-shadow-700'
      }
    >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {activeTasks?.length ? (
            <>
              <div className="flex flex-col items-start justify-start gap-0.5">
                <h2 className="text-xl font-semibold leading-normal text-[#2b3674]">
                  {t('profile.Quests')}
                </h2>
                <p className="text-base font-normal leading-tight text-[#8f9bba]">
                  {t('profile.Daily_and_one_time_classes')}
                </p>
              </div>
              {activeTasks?.map((item) => (
                <QuestCard
                  key={item.id}
                  title={item.title}
                  award={item.award}
                  taskRewardType={item.task_reward_type}
                  onTaskStart={() => handleCondition(item.id)}
                  onTaskCondition={() => handleCondition(item.id)}
                  onTaskReward={() => handleReward(item)}
                  onClick={() => setCheckTask(item)}
                  status={item.task_progress.status}
                  image={item.image}
                />
              ))}
            </>
          ) : (
            <>
              <img src={NoTaskImg} alt="no task" className="mx-auto" />
              <div className="w-full text-center text-base font-semibold leading-tight text-[#7551ff]">
                {t('task.You_have_completed_all_the_quests')}
              </div>
            </>
          )}
          {endedTasks?.length ? (
            <>
              <h3 className="!text-sm font-semibold leading-[18px] text-[#2b3674]">
                {t('quest.Completed')}
              </h3>
              {endedTasks?.map((item) => (
                <QuestCard
                  key={item.id}
                  title={item.title}
                  award={item.award}
                  taskRewardType={item.task_reward_type}
                  onTaskCondition={() => handleToggle()}
                  onClick={() => setCheckTask(item)}
                  status={item.task_progress.status}
                  image={item.image}
                />
              ))}
            </>
          ) : null}
        </>
      )}
      <BottomSheetModal close={handleToggle} opened={isOpen}>
        {checkTask?.task_progress.status === 'REWARD_RECEIVED' ? (
          <CongratulationTaskModal
            onToggle={handleToggle}
            award={checkTask?.award}
          />
        ) : (
          <TaskInfoModal
            id={checkTask?.id}
            title={condition?.title}
            notice={condition?.notice}
            description={condition?.description}
            onStart={() => checkTask?.id && handleStart(checkTask)}
            onReward={() => checkTask?.id && handleReward(checkTask)}
            error={reward?.error || condition?.error}
            loading={isRewardLoading}
            isLoading={isConditionLoading}
            status={checkTask?.task_progress.status}
            award={checkTask?.award}
            taskType={checkTask?.task_type}
            link={checkTask?.link}
          />
        )}
      </BottomSheetModal>
    </div>
  );
};
