import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '../../hooks/useNavigate';
import { MButton } from '../../horizon-components/MButton/ui/MButton';
import { AuthRoutes, MainRoutes } from './Routes/types/routes';
import { ChangeLanguage } from '../../features/change-language';

const NavbarMenuAnonymous: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['translation']);

  const goToSignIn = () => navigate(MainRoutes.auth + AuthRoutes.signin);
  const goToSignUp = () => navigate(MainRoutes.auth + AuthRoutes.signup);

  return (
    <div className="relative flex w-fit items-center gap-[12px] rounded-full bg-white p-2 shadow-xl shadow-shadow-500 md:flex-grow-0 md:gap-[16px] md:px-4 md:py-3">
      <ChangeLanguage />
      <div className="flex gap-2">
        <MButton
          onClick={goToSignIn}
          color={'primary'}
          variant={'outline'}
          className="!hidden md:!flex"
        >
          {t('profile.logIn')}
        </MButton>
        <MButton
          onClick={goToSignUp}
          color={'primary'}
          variant={'highlighted'}
          className="!hidden md:!flex"
        >
          {t('profile.signUp')}
        </MButton>
        <MButton
          onClick={goToSignIn}
          color={'primary'}
          variant={'highlighted'}
          className="!block md:!hidden"
        >
          {t('profile.logIn')}
        </MButton>
      </div>
    </div>
  );
};

export default NavbarMenuAnonymous;
