import { useEffect, useState } from 'react';
import { useUserContext } from '../providers';
import { getTokenomicConfigs } from '../api/auth';
import { getUserSomeDataStorage } from '../api';

export const useDailyRewardModal = () => {
  const [isStartOpen, setIsStartOpen] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const { user } = useUserContext();

  useEffect(() => {
    const checkDataLoaded = async () => {
      try {
        await getTokenomicConfigs();
        setIsDataLoaded(true);
      } catch (error) {
        console.error('Error loading data:', error);
      }
    };

    checkDataLoaded();
  }, []);

  useEffect(() => {
    if (user?.guid && isDataLoaded) {
      const lastVisit = getUserSomeDataStorage(`lastRewardClaim`, user.guid);
      const today = new Date().toLocaleDateString();

      if (lastVisit !== today) {
        hndlOpenStartModal();
      }
    }
  }, [user, isDataLoaded]);

  const hndlOpenStartModal = () => {
    setIsStartOpen(!isStartOpen);
  };

  return { isStartOpen, hndlOpenStartModal };
};
