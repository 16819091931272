import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  getStorage,
  getUserSomeDataStorage,
  getUserStorage,
  removeUserSomeDataStorage,
  removeUserStorage,
  saveUserStorage,
  setUserSomeDataStorage,
} from '../api';
import {
  deleteAccount as apiDeleteAccount,
  getEdresWallets,
  setPublicReferralsMyReferralCode,
  TempUserData,
} from '../api/auth';
import { usePageTracking } from '../hooks/useAnalytics';
import useFetch from '../hooks/useFetch';
import { useNavigate } from '../hooks/useNavigate';
import {
  AuthRoutes,
  MainRoutes,
} from '../horizon-layout/MainLayout/Routes/types/routes';
import { UserProfile } from '../models';
import { ERPWallet } from '../models/Wallet';
import { useModal } from './ModalProvider';
import { Modal } from '../horizon-components/Modal';
import { StartModal } from '../widgets/StartModal';

type UserContextValue = {
  user: UserProfile | null;
  logout: () => void;
  saveUser: (user: UserProfile) => void;
  saveTempUserData: (user: TempUserData) => void;
  tempUserData: TempUserData | null;
  deleteAccount: () => void;
  wallet: ERPWallet | null;
  updateWallet: () => Promise<ERPWallet[] | undefined>;
  setFirstLogin: (value: boolean) => void;
  setIsTrained: (value: boolean) => void;
  referral: string | null;
  firstLogin: boolean;
  userSomeData?: { [key: string]: string | null | undefined } | string | null;
  setUserSomeData: (key: string, value: any) => void;
  getUserSomeData: (key: string) => string | null | undefined;
  removeUserSomeData: (key: string) => void;
};

const UserContext = createContext<UserContextValue>({} as UserContextValue);

const UserProvider: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [user, setUser] = useState<UserProfile | null>(getUserStorage());
  const [tempUserData, setTempUserData] = useState<TempUserData | null>(null);
  const [firstLogin, setFirstLogin] = useState(false);
  const [isTrained, setIsTrained] = useState(false);
  const refCode = searchParams.get('ref-code');
  const {
    data: walletData,
    isLoading,
    fetchData: fetchEdresWallets,
  } = useFetch(getEdresWallets);
  const wallet = walletData?.[0] || null;
  const { data: referralCode, fetchData: fetchReferralCode } = useFetch(
    setPublicReferralsMyReferralCode
  );
  const { openModal, closeModal } = useModal();

  useEffect(() => {
    // Показать рефералку если это первая авторизация, прошел обучение и нет рефералки
    if (firstLogin && isTrained && !refCode) {
      openModal('RefCodeModal');
    }
  }, [firstLogin, isTrained, refCode]);

  const handleCloseFirstModal = () => {
    setFirstLogin(false);
    closeModal('RefCodeModal');
  };

  usePageTracking();

  useEffect(() => {
    refCode && sessionStorage.setItem('refCode', refCode);
  }, [refCode]);

  useEffect(() => {
    if (!isLoading && user?.guid) {
      fetchEdresWallets();
      fetchReferralCode(user?.guid);
    }
  }, [user?.guid]);

  const saveUser = useCallback((user: UserProfile) => {
    saveUserStorage(user);
    setUser(user);
  }, []);

  const saveTempUserData = useCallback((user: TempUserData) => {
    setTempUserData(user);
  }, []);

  const logout = useCallback(() => {
    removeUserStorage();
    setUser(null);
    navigate(MainRoutes.auth + AuthRoutes.signin);
  }, [navigate]);

  const deleteAccount = useCallback(async () => {
    await apiDeleteAccount();
    logout();
  }, [logout]);

  // User Some Data
  const userSomeData = useMemo(() => {
    if (user?.guid) {
      const storageData = getStorage(user?.guid);
      if (storageData) {
        return storageData;
      }
    }
  }, [user]);

  const getUserSomeData = useCallback(
    (key: string) => {
      return getUserSomeDataStorage(key, user?.guid);
    },
    [user?.guid]
  );

  const removeUserSomeData = useCallback(
    (key: string) => {
      removeUserSomeDataStorage(key, user?.guid);
    },
    [user?.guid]
  );

  const setUserSomeData = useCallback(
    (key: string, value: any) => {
      setUserSomeDataStorage(key, value, user?.guid);
    },
    [user?.guid]
  );
  // User Some Data

  return (
    <UserContext.Provider
      value={{
        saveUser,
        logout,
        user,
        saveTempUserData,
        tempUserData,
        deleteAccount,
        wallet,
        updateWallet: fetchEdresWallets,
        referral: referralCode,
        setFirstLogin,
        firstLogin,
        setIsTrained,
        userSomeData,
        setUserSomeData,
        getUserSomeData,
        removeUserSomeData,
      }}
    >
      {children}
      {/*{!!user?.guid && !!firstLogin && !refCode && (*/}
      {/*  <Modal modalId="RefCodeModal" onClose={handleCloseFirstModal}>*/}
      {/*    <StartModal />*/}
      {/*  </Modal>*/}
      {/*)}*/}
    </UserContext.Provider>
  );
};

const useUserContext = () => useContext(UserContext);

export { UserContext, UserProvider, useUserContext };
