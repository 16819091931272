/* eslint-disable @typescript-eslint/no-unused-vars */
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getPrivateDailyReward, setPrivateDailyReward } from '../../api/auth';
import { Loader } from '../../components/Loader';
import { sendEarnEDRES, sendRewardReceived } from '../../hooks/useAnalytics';
import useFetch from '../../hooks/useFetch';
import { MButton } from '../../horizon-components/MButton';
import { useUserContext } from '../../providers';
import { useRewardContext } from '../../providers/RewardProvider';
import getErrorMessages from '../../utils/getErrorMessages';
import { DailyRewardCard } from '../../widgets/DailyRewardCard';
import Turnstile from 'react-turnstile';
import TurnstileImg from '../../assets/images/cloudflare/turnstile.webp';
import CloseIcon from '../../assets/icons/close.svg';
import './style.scss';
import { setUserSomeDataStorage } from '../../api';

interface DailyRewardModalProps {
  className?: string;
  onClose?: () => void;
}

export const DailyRewardModal = ({ className, onClose }: DailyRewardModalProps) => {
  const { t } = useTranslation(['translation']);
  const {
    data,
    isLoading,
    fetchData: fetchDailyReward,
  } = useFetch(getPrivateDailyReward);
  const { updateWallet, user } = useUserContext();
  const { rewards, isLoading: isLoadingRewards } = useRewardContext();

  useEffect(() => {
    fetchDailyReward();
  }, [fetchDailyReward, t]);

  const [token, setToken] = useState<string | undefined>(undefined);

  const onGetReward = () => {
    if (!token) {
      console.log('No token');
      toast(getErrorMessages('Captcha not verified'), {
        type: 'error',
      });
    } else {
      setPrivateDailyReward(token)
        .then(() => {
          // TODO set amount earned
          sendEarnEDRES(0);
          updateWallet();
          toast(t('reward.daily.success'), { type: 'success' });
        })
        .catch((error) => {
          toast(getErrorMessages(error?.response?.data), {
            type: 'error',
          });
        })
        .finally(() => {
          const today = new Date().toLocaleDateString();
          setUserSomeDataStorage('lastRewardClaim', today, user?.guid);
        });
      sendRewardReceived();
      onClose?.();
    }
  };

  return (
    <div className={classNames('DailyRewardModal', className)}>
      <img
        src={CloseIcon}
        alt="Close"
        className="DailyRewardModal__closeIcon"
        onClick={onClose}
      />
      <div className="DailyRewardModal__head">
        <h1>{t('reward.daily.title')}</h1>
        {/* <p>{t('reward.daily.description')}</p> */}
      </div>
      {isLoading || isLoadingRewards ? (
        <Loader />
      ) : (
        <>
          <div className="DailyRewardModal__days">
            {data &&
              data.daily_info.map((day, idx) => (
                <DailyRewardCard
                  title={`${t('reward.day')} ${idx + 1}`}
                  earn={`${Math.round(
                    Number(
                      rewards?.find(
                        (reward) =>
                          reward.tokenomic_type ===
                          'daily_reward_streak_' + (idx + 1)
                      )?.value || 0
                    )
                  )}`}
                  multiplier={`${Math.round(
                    Number(
                      rewards?.find(
                        (reward) =>
                          reward.tokenomic_type ===
                          'wiz_daily_streak_multiplier_' + (idx + 1)
                      )?.value || 0
                    )
                  )}X`}
                  status={day.status}
                  key={idx}
                  onClick={day.status === 'can_be_claimed' ? onGetReward : undefined}
                />
              ))}
          </div>
          <div style={{ height: '65px', width: '300px', position: 'relative' }}>
            <Turnstile
              sitekey="0x4AAAAAAAynUyjZse1JlJmu"
              theme="light"
              className="DailyRewardModal__turnstile"
              language={`${t('language')}`}
              onSuccess={(inToken) => {
                console.log('CAPTCHA token:', inToken);
                setToken(inToken);
              }}
              onError={(e) => {
                console.log('CAPTCHA error:', e);
              }}
            />
            <div className="DailyRewardModal__loadingOverlay">
              <img
                src={TurnstileImg}
                alt="Loading"
                className="DailyRewardModal__image"
              />
            </div>
          </div>

          <MButton
            variant="highlighted"
            color="primary"
            className="modal-h sm-max:w-full md:!px-8 md:!py-6 md:!text-lg"
            onClick={onGetReward}
          >
            {t('buttons.Collect_the_reward')}
          </MButton>
        </>
      )}
    </div>
  );
};
