import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/pages/main/arrow-right.svg';
import IconBigImg from '../../assets/images/pages/main/ER-big.webp';
import IconSmallImg from '../../assets/images/pages/main/ER-small.webp';
import BgImg from '../../assets/images/pages/main/new-earn-bg.webp';
import MobileBgImg from '../../assets/images/pages/main/new-earn-mobile-bg.webp';
import { Link } from '../../horizon-components/Link';
import { MButton } from '../../horizon-components/MButton';
import { MainRoutes } from '../../horizon-layout/MainLayout/Routes/types/routes';
import './style.scss';

interface MainPageEarnProps {
  className?: string;
  hasIcon?: boolean;
}

export const MainPageEarn = ({ className, hasIcon }: MainPageEarnProps) => {
  const { t } = useTranslation(['translation']);
  const [backgroundImage, setBackgroundImage] = useState(BgImg);

  useEffect(() => {
    const updateBackground = () => {
      if (window.innerWidth < 768) {
        setBackgroundImage(MobileBgImg);
      } else {
        setBackgroundImage(BgImg);
      }
    };

    updateBackground();
    window.addEventListener('resize', updateBackground);

    return () => window.removeEventListener('resize', updateBackground);
  }, []);

  return (
    <Link to={MainRoutes.wiz} className={classNames(className)}>
      <div
        className={'MainPageEarn'}
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className={classNames('MainPageEarn__content', { hasIcon })}>
          <h4>{t('main.Earn_money')}</h4>

          <p>{t('main.Play_WIZ_and_get_our_ERP_points')}</p>
        </div>

        {hasIcon && (
          <>
            <img
              src={IconBigImg}
              alt={t('main.Earn_money')}
              className="icon hidden lg:block"
            />
            <img
              src={IconSmallImg}
              alt={t('main.Earn_money')}
              className="icon lg:hidden"
            />
          </>
        )}
        <MButton
          variant="secondary"
          color="primary"
          className="z-10 lg-max:!px-2.5 lg-max:!py-1.5"
        >
          {t('buttons.play')} <ArrowRightIcon />
        </MButton>
      </div>
    </Link>
  );
};
