import {
  AuthorizationData,
  Role,
  UserMeta,
  UserProfile,
  UserSocialMeta,
} from '../models';
import { UserFriendship } from '../models/Friendship';
import { TAgree, TMainContent, TPossibilities, TSocial } from '../models/Main';
import { OneSignalType, TNotifications } from '../models/Notifications';
import { ResponsePagination } from '../models/Response';
import {
  DailyRewardStatusEnum,
  TDailyReward,
  TTokenomicConfigs,
} from '../models/Reward';
import { TeacherProfile } from '../models/Teacher';
import { ERPWallet, Wallet } from '../models/Wallet';
import instance, {
  AUTH_API_URL,
  REACT_APP_API_URL,
  WEBER_API_URL,
} from './instance';
import { CommonResponse } from './utils';

export interface SignInResponse extends CommonResponse {
  access: string;
  refresh: string;
}

export type TempUserData = {
  email: string;
  user_type: Role;
};

export function createJWT(email: string, password: string): Promise<SignInResponse> {
  return instance()
    .post(`${AUTH_API_URL}/private/auth/jwt/create/`, {
      email,
      password,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function refreshToken(refresh: string): Promise<SignInResponse> {
  return instance(false)
    .post(`${AUTH_API_URL}/private/auth/jwt/refresh/`, {
      refresh,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function forgotPassword(email: string): Promise<void> {
  return instance()
    .post(`${AUTH_API_URL}/private/auth/reset_password/`, {
      email,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function authMe(autoRefreshToken = true): Promise<UserProfile> {
  return instance(true, true, undefined, autoRefreshToken)
    .get(`${AUTH_API_URL}/private/auth/me/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function publicUsers(
  search: string
): Promise<ResponsePagination<UserFriendship>> {
  return instance()
    .get(`${WEBER_API_URL}/find-connections/`, {
      params: { search },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function signUp(email: string, password: string): Promise<TempUserData> {
  return instance()
    .post(`${AUTH_API_URL}/private/auth/register/`, {
      email,
      password,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function checkCode(
  email: string,
  confirmation_code: string
): Promise<{ token: string }> {
  return instance()
    .post(`${AUTH_API_URL}/private/auth/confirm-email/`, {
      email,
      confirmation_code,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function checkCodeResetPassword(
  email: string,
  confirmation_code: string
): Promise<{ token: string }> {
  return instance()
    .post(`${AUTH_API_URL}/private/auth/reset_password_check_code/`, {
      email,
      confirmation_code,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function resetPasswordConfirm(
  email: string,
  confirmation_code: string,
  password: string
): Promise<{ token: string }> {
  return instance()
    .post(`${AUTH_API_URL}/private/auth/reset_password_confirm/`, {
      email,
      confirmation_code,
      password,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getTeacherProfile(): Promise<TeacherProfile> {
  return instance()
    .get(`/profile/teacher/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function setInstitutionBasicInfo(
  institution_name: string,
  phone_number: string,
  address: string,
  country: string,
  city: string,
  website_url: string
): Promise<void> {
  return instance()
    .post(`${AUTH_API_URL}/private/institution-meta/step_1/`, {
      name: institution_name,
      phone_number,
      address,
      country,
      city,
      website: website_url,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function setInstitutionApplicationInfo(
  institution_type: string,
  area_of_expertise: string[],
  applying_full_name: string,
  applying_email: string,
  applying_position: string,
  applying_phone_number: string
): Promise<void> {
  return instance()
    .post(`${AUTH_API_URL}/private/institution-meta/step_2/`, {
      applier_full_name: applying_full_name,
      applier_email: applying_email,
      applier_position: applying_position,
      applier_phone_number: applying_phone_number,
      areas_of_expertise: area_of_expertise,
      type: institution_type,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function setInstitutionApplicationInfoNew(form: FormData): Promise<void> {
  return instance()
    .post(`${AUTH_API_URL}/private/institution-meta/step_2/`, form)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function institutionAttachAcFile(file: File): Promise<void> {
  const formData = new FormData();
  formData.set('file', file, file.name);

  return instance()
    .post(`${AUTH_API_URL}/private/institution-ac-file/`, formData)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function setSocialMedia(social_links: {
  about: string | null;
  linked_in: string | null;
  instagram: string | null;
}): Promise<void> {
  return instance()
    .post(`${AUTH_API_URL}/private/social-meta/`, {
      ...social_links,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function setSocialMeta(
  page?: number,
  user__guid?: string,
  user__email?: string,
  id?: string
): Promise<ResponsePagination<UserSocialMeta>> {
  return instance()
    .get(`${AUTH_API_URL}/private/social-meta/`, {
      params: {
        id,
        user__guid,
        user__email,
        page,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function editUserLogo(
  role: 'teacher' | 'institution' | 'student',
  file?: File
): Promise<void> {
  const body = new FormData();
  if (file) {
    if (role === 'institution') {
      body.append('logo', file);
    } else {
      body.append('profile_image', file);
    }
  }
  return instance()
    .post(
      `${AUTH_API_URL}/auth/`,
      !!file
        ? body
        : role === 'institution'
          ? { logo: null }
          : { profile_image: null }
    )
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

// export function getGoogleOAuthUrl(): Promise<string> {
//   return instance()
//     .get<{ auth_url: string }>(`${AUTH_API_URL}/google/url/`)
//     .then((response) => response?.data?.auth_url)
//     .catch((error) => {
//       throw error;
//     });
// }
//
// export function getAppleOAuthUrl(): Promise<string> {
//   return instance()
//     .get<{ auth_url: string }>(`${AUTH_API_URL}/apple/get-oauth-url/`)
//     .then((response) => response?.data?.auth_url)
//     .catch((error) => {
//       throw error;
//     });
// }

export function authorizeViaGoogle(
  code: string,
  redirectUri: string
): Promise<AuthorizationData> {
  return instance()
    .get(`${REACT_APP_API_URL}/auth/google/callback/`, {
      params: { code, redirect_uri: redirectUri },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function authorizeViaApple(idToken: string): Promise<AuthorizationData> {
  return instance()
    .post(`${REACT_APP_API_URL}/auth/apple/oauth-submit/`, {
      id_token: idToken,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function authorizeViaTelegramApp(
  initData: string
): Promise<AuthorizationData> {
  return instance()
    .post(`${AUTH_API_URL}/private/auth/auth-via-telegram/`, {
      init_data: initData,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function authorizeViaTelegramOauth(
  id: string,
  hash: string,
  auth_date: string,
  first_name: string | null,
  last_name: string | null,
  username: string | null,
  photo_url: string | null
): Promise<AuthorizationData> {
  return instance()
    .post(`${AUTH_API_URL}/private/auth/auth-via-telegram-oauth/`, {
      id,
      hash,
      auth_date,
      first_name,
      last_name,
      username,
      photo_url,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function changeInstitutionMeta(
  id: string,
  body: {
    applier_full_name: string;
    phone_number: string;
    address: string;
    country: string;
    city: string;
    website: string;
    logo?: File;
  }
): Promise<UserProfile['institution_meta']> {
  const _body = new FormData();
  if (body.logo) {
    _body.append('logo', body.logo);
  }
  _body.append('applier_full_name', body.applier_full_name);
  _body.append('phone_number', body.phone_number);
  _body.append('address', body.address);
  _body.append('country', body.country);
  _body.append('city', body.city);
  _body.append('website', body.website);
  return instance()
    .patch(`${AUTH_API_URL}/private/institution-meta/${id}/`, _body)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function changeUserMeta(
  id: string,
  body: {
    username?: string;
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    address?: string;
    education?: string;
    major?: string;
    degree?: string;
    position?: string;
    profile_image?: File;
  }
): Promise<UserProfile['user_meta']> {
  const _body = new FormData();

  // Append the profile image if present
  body.profile_image && _body.append('profile_image', body.profile_image);

  // Append other form fields
  body.username && _body.append('username', body.username);
  body.firstName && _body.append('first_name', body.firstName);
  body.lastName && _body.append('last_name', body.lastName);
  body.phoneNumber && _body.append('phone_number', body.phoneNumber);
  body.address && _body.append('address', body.address);
  body.education && _body.append('education', body.education);
  body.major && _body.append('major', body.major);
  body.degree && _body.append('degree', body.degree);
  body.position && _body.append('position', body.position);

  // Make the patch request
  return instance()
    .patch(`${AUTH_API_URL}/private/user-meta/${id}/`, _body)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function changeUserMetaAvatar(
  id: string,
  body: {
    profile_image?: File;
    cover_image?: File;
  }
): Promise<UserProfile['user_meta']> {
  const _body = new FormData();

  // Append the profile image if present
  if (body.profile_image) {
    _body.append('profile_image', body.profile_image, body.profile_image.name);
  }
  if (body.cover_image) {
    _body.append('cover_image', body.cover_image, body.cover_image.name);
  }

  // Make the patch request
  return instance()
    .patch(`${AUTH_API_URL}/private/user-meta/${id}/`, _body)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function changePassword(body: {
  email: string;
  old_password: string;
  new_password: string;
  confirm_password: string;
}): Promise<void> {
  return instance()
    .post(`${AUTH_API_URL}/private/auth/change-email-password/`, body)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getUserMeta(id: string): Promise<UserMeta> {
  return instance()
    .get(`${AUTH_API_URL}/private/user-meta/${id}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

// Main Page
export function getMainContent(): Promise<TMainContent> {
  return instance()
    .get(`${AUTH_API_URL}/public/main_page/maincontent`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getMainPossibilities(): Promise<TPossibilities[]> {
  return instance()
    .get(`${AUTH_API_URL}/public/main_page/possibilities`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getMainEarnMoney(): Promise<TPossibilities[]> {
  return instance()
    .get(`${AUTH_API_URL}/public/main_page/earn-money-possibilities`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getMainContacts(): Promise<TSocial[]> {
  return instance()
    .get(`${AUTH_API_URL}/public/main_page/contacts`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}
// Main Page

export function getDocsAgree(): Promise<ResponsePagination<TAgree>> {
  return instance()
    .get(`${AUTH_API_URL}/public/user-agreement/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

// Notifications
export function getNotifications(
  page?: number
): Promise<ResponsePagination<TNotifications>> {
  return instance()
    .get(`${AUTH_API_URL}/server/notifications/`, { params: { page } })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getPrivateDailyReward(): Promise<{
  daily_info: {
    status: DailyRewardStatusEnum;
  }[];
  is_captcha_passed: boolean;
}> {
  return instance()
    .get(`${AUTH_API_URL}/private/daily-reward/daily-reward/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function setPrivateDailyReward(token: string): Promise<TDailyReward> {
  return instance()
    .post(`${AUTH_API_URL}/private/daily-reward/daily-reward/`, {
      captcha_token: token,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

let savedTokenomiccConfigPromise: Promise<TTokenomicConfigs[]> | null = null;
export function getTokenomicConfigs(): Promise<TTokenomicConfigs[]> {
  if (savedTokenomiccConfigPromise) {
    return savedTokenomiccConfigPromise;
  }
  const promise = fetch(`${AUTH_API_URL}/public/tokenomic-configs/`, {
    method: 'GET',
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
  savedTokenomiccConfigPromise = promise;
  return promise;
}

export function setPublicReferralsMyReferralCode(userId: string): Promise<string> {
  return instance()
    .post(`${AUTH_API_URL}/public/referrals/get-my-referral-code/${userId}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function setPrivateUseReferralCode(code: string): Promise<{ code: string }> {
  return instance()
    .post(`${AUTH_API_URL}/private/referrals/use-referral-code/`, { code })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getEdresWallets(): Promise<ERPWallet[]> {
  return instance()
    .get(`${AUTH_API_URL}/private/edres-wallets/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function setOneSignalSubscriptions(): Promise<OneSignalType> {
  return instance()
    .post(`${AUTH_API_URL}/private/one-signal/subscriptions`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getEdresWalletsTransactions(
  page: number
): Promise<ResponsePagination<Wallet>> {
  return instance()
    .get(`${AUTH_API_URL}/private/edres-wallets-transactions/`, {
      params: {
        page,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function deleteAccount(): Promise<void> {
  return instance()
    .post(`${AUTH_API_URL}/private/auth/delete-account/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function updateServerUserLanguage(langauge: string): Promise<void> {
  return instance()
    .post(`${AUTH_API_URL}/private/auth/set-user-language/`, { language: langauge })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}
